<template>
  <aRow>
    <aCol :span="24">
      <aAlert
        banner
        type="info"
        class="f12 mb-20"
        message="Selecione os serviços que deseja adicionar"
      />
    </aCol>
    <aCol :span="24">
      <aCheckboxGroup v-model="selectedServices">
        <aCheckbox
          v-for="(product, index) in products"
          :key="index"
          :value="product.value"
          :disabled="product.disabled"
          class="f12"
        >
          {{ product.label }}
        </aCheckbox>
      </aCheckboxGroup>
    </aCol>

    <aCol class="a-center mt-30" :span="24">
      <aButton
        type="primary"
        :loading="loadingSubmit"
        @click="submitAddServices"
      >
        Adicionar Serviços
      </aButton>
    </aCol>
  </aRow>
</template>

<script>
export default {
  name: "ContractModalAddServicesForm",
  props: {
    tempContract: Object,
    contract: Object,
  },
  data() {
    return {
      loadingSubmit: false,
      selectedServices: [],
      products: [
        {
          label: "Hotel",
          value: "hotel",
          disabled: false,
        },
        {
          label: "Aéreo",
          value: "flight",
          disabled: false,
        },
        {
          label: "Serviço",
          value: "service",
          disabled: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    submitAddServices() {
      if (this.selectedServices.length === 0) {
        this.$message.warning("Selecione ao menos um serviço");
        return;
      }

      this.loadingSubmit = true;

      const contractData = JSON.parse(JSON.stringify(this.contract.raw));

      delete contractData.id;
      delete contractData.financial_cost;
      delete contractData.first_checkin;
      delete contractData.last_checkout;
      delete contractData.marketing_types;
      delete contractData.payment_methods;
      delete contractData.profit_percentage;
      delete contractData.profit_value;
      delete contractData.release_date;
      delete contractData.sale_id;
      delete contractData.taxes_value;
      delete contractData.value;
      delete contractData.value_to_pay;

      this.$http
        .post(`/contract-v2/create`, {
          ...contractData,
          contracted_services: JSON.stringify(this.selectedServices),
          status: "edit",
          based_contract_id: this.contract.raw.id,
          based_sale_id: this.contract.raw.sale_id,
          block_customer_edit: true,
          active_tab: this.selectedServices[0],
        })
        .then(({ data }) => {
          this.$message.success("Contrato gerado com sucesso!");
          this.$router.push(`/contracts/edit/${data.id}`);
          this.$emit("closeAddServicesModal");
        })
        .finally(() => (this.loadingSubmit = false));
    },
  },
};
</script>
