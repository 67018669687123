<template>
  <aRow style="padding-right: 10px">
    <aCol
      class="content-row"
      v-for="(item, index) in theList()"
      :key="index"
      :span="24"
    >
      <aRow class="tab-header" type="flex" justify="space-between">
        <aCol>
          <h3 class="cprimary">{{ item.id }} - {{ item.name }}</h3>
        </aCol>
        <aCol>
          <a v-if="cancellationMode" @click="onClickOpenCancelModal(item)">
            <img
              class="mr-5"
              src="@/assets/images/dashboard/contracts/cancel-product.png"
              width="20"
              alt="icon"
            />
          </a>
        </aCol>
      </aRow>

      <ContractModalHotelRow
        type="view"
        :product="item"
        :tempContract="tempContract"
        :allContractData="allContractData"
      />
    </aCol>
    <aModal
      class="product-cancel-modal"
      :title="false"
      :visible="openCancelModal"
      :footer="false"
      :width="800"
      @cancel="onClickCloseCancelModal"
    >
      <CancelHotelModal
        v-if="openCancelModal"
        :productToCancel="productToCancel"
        :tempContract="tempContract"
        :allContractData="allContractData"
        :cancelSpecificProducts="cancelSpecificProducts"
        @onChangeCancelProductData="onChangeCancelProductData"
      />
    </aModal>
  </aRow>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import ContractModalHotelRow from "@/components/contracts/sections/ContractModalHotelRow.vue";
import CancelHotelModal from "../modals/CancelHotelModal.vue";

export default {
  name: "ContractHotelDetails",
  props: {
    tempContract: Object,
    allContractData: Object,
    cancellationMode: Boolean,
    cancelSpecificProducts: Boolean,
  },
  components: {
    ContractModalHotelRow,
    CancelHotelModal,
  },
  mixins: [formatThings],
  data() {
    return {
      productToCancel: false,
      openCancelModal: false,
    };
  },
  methods: {
    onChangeCancelProductData(tempContract) {
      this.$emit("onChangeCancelProductData", tempContract);
    },
    onClickOpenCancelModal(product) {
      this.productToCancel = product;
      this.openCancelModal = true;
    },
    onClickCloseCancelModal() {
      this.productToCancel = {};
      this.openCancelModal = false;
    },
    thePassenger(id) {
      let travellers = JSON.parse(this.tempContract.travellers_rows);
      let theTraveller = {};

      travellers.forEach((traveller) => {
        if (traveller.id == id) {
          theTraveller.name = `${
            this.tempContract[`traveller_${traveller.id}_first_name`]
          } ${this.tempContract[`traveller_${traveller.id}_last_name`]}`;

          theTraveller.birthday = `${
            this.tempContract[`traveller_${traveller.id}_birthday`]
          }`;

          if (this.tempContract[`traveller_${traveller.id}_gender`]) {
            theTraveller.gender = `${
              this.tempContract[`traveller_${traveller.id}_gender`]
            }`;
          }
        }
      });

      return theTraveller;
    },
    theList() {
      let arr = [];
      let contractedServices = JSON.parse(
        this.tempContract.contracted_services
      );

      if (contractedServices.includes("hotel")) {
        JSON.parse(this.tempContract["hotel_rows"]).forEach((hotel) => {
          let allRooms = [];

          hotel.accommodation.forEach((room) => {
            let allTravellers = [];

            room.travellers.forEach((traveller) => {
              allTravellers.push(
                this.thePassenger(
                  this.tempContract[
                    `hotel_${hotel.id}_accommodation_${room.id}_traveller_${traveller.id}`
                  ]
                )
              );
            });

            allRooms.push({
              name: `${
                this.tempContract[`hotel_${hotel.id}_accommodation_${room.id}`]
              } (${
                this.tempContract[
                  `hotel_${hotel.id}_accommodation_${room.id}_type`
                ] ?? "Preencher"
              })`,
              travellers: allTravellers,
            });
          });

          let checkin = this.tempContract[`hotel_${hotel.id}_checkin`];
          checkin = checkin.split("T");

          let checkout = this.tempContract[`hotel_${hotel.id}_checkout`];
          checkout = checkout.split("T");

          arr.push({
            id: hotel.id,
            name:
              this.tempContract[`hotel_${hotel.id}_name`] ??
              this.tempContract[`hotel_${hotel.id}_name_infotera`],
            checkin: checkin[0],
            checkout: checkout[0],
            days: this.tempContract[`hotel_${hotel.id}_rates`],
            nights: this.tempContract[`hotel_${hotel.id}_nights`],
            diet: this.tempContract[`hotel_${hotel.id}_diet`],
            locator: this.tempContract[`hotel_${hotel.id}_locator`],
            rooms: allRooms,
          });
        });
      }

      return arr;
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 13px
  font-weight: 500
  .tab-header
    margin-bottom: 15px
    color: #be4178
    text-transform: uppercase
    border-bottom: 1px solid #ececec
    h3
      font-size: 14px
      font-weight: 600
</style>
