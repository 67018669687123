<template>
  <section>
    <aAlert
      v-if="type === 'edit'"
      class="mb-10 f12"
      type="info"
      banner
      message="Selecione os trechos que deseja alterar."
    />

    <div class="flight-row">
      <aCheckboxGroup v-model="selectedRows" @change="onChangeProductRow">
        <div
          v-for="(section, index) in product.sections"
          class="infos mb-10"
          :key="index"
        >
          <aRow :gutter="20">
            <aCol v-if="type === 'edit'" :span="1">
              <aCheckbox :value="section.id" />
            </aCol>

            <aCol :span="type === 'edit' ? 23 : 24">
              <aRow :gutter="20">
                <aCol :span="4">
                  <span class="label">Origem:</span>
                  {{ section.origin }}
                </aCol>

                <aCol :span="4">
                  <span class="label">Destino:</span>
                  {{ section.destination }}
                </aCol>

                <aCol :span="5">
                  <span class="label">Voo:</span>
                  {{ section.type }} (Nº {{ section.number }})
                </aCol>

                <aCol :span="5">
                  <span class="label"> Classe:</span>
                  {{ section.class }}
                </aCol>

                <aCol :span="5">
                  <span class="label"> Cia:</span>
                  {{ section.airline }}
                </aCol>
                <aCol class="mt-10" :span="6">
                  <span class="label"> Embarque:</span>
                  {{ section.departure }}
                </aCol>
                <aCol class="mt-10" :span="6">
                  <span class="label"> Desembarque:</span>
                  {{ section.arrival }}
                </aCol>
                <aCol class="mt-10" :span="6">
                  <span class="label"> Duração:</span>
                  {{ section.duration }}
                </aCol>

                <aCol class="mt-10" :span="6">
                  <span class="label"> Paradas:</span>
                  {{ section.stops }}
                </aCol>
              </aRow>
            </aCol>
          </aRow>

          <div class="form" v-if="selectedRows.includes(section.id)">
            <ContractCancelFlightForm
              class="mt-30"
              :product="product"
              :tempContract="tempContract"
              :rowId="section.id"
              @onChangeCancelProductData="onChangeCancelProductData"
            />
          </div>
        </div>
      </aCheckboxGroup>

      <aRow class="" :gutter="20">
        <aCol class="mb-5" :span="24"><h4>VIAJANTES</h4> </aCol>
        <aCol class="travellers" :span="24">
          <aCheckboxGroup
            v-model="selectedTravelersRows"
            @change="onChangeTravelerRow"
            style="width: 100%"
          >
            <div
              class="traveller upper f12"
              v-for="(traveller, index) in product.travellers"
              :key="index"
            >
              <aRow>
                <aCol v-if="cancelSpecificTravelers" :span="1">
                  <aCheckbox :value="traveller.id" />
                </aCol>

                <aCol
                  :class="
                    selectedTravelersRows.includes(traveller.id) ? 'cut' : ''
                  "
                  :span="14"
                >
                  {{ traveller.name }}
                </aCol>

                <aCol
                  :class="
                    selectedTravelersRows.includes(traveller.id) ? 'cut' : ''
                  "
                  :span="6"
                >
                  {{ formatMultiDates(traveller.birthday) }}
                </aCol>

                <aCol
                  v-if="
                    selectedTravelersRows.includes(traveller.id) &&
                    cancelSpecificTravelers
                  "
                  :span="2"
                  class="a-right"
                >
                  <img
                    class="mr-5 c-pointer"
                    src="@/assets/images/dashboard/contracts/cancel-product.png"
                    width="15"
                    alt="icon"
                  />
                </aCol>
              </aRow>
            </div>
          </aCheckboxGroup>
        </aCol>
      </aRow>
    </div>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import ContractCancelFlightForm from "./ContractCancelFlightForm.vue";

export default {
  name: "ContractModalFlightRow",
  props: {
    product: Object,
    tempContract: Object,
    type: String,
    cancelSpecificTravelers: Boolean,
  },
  components: { ContractCancelFlightForm },
  mixins: [formatThings],
  data() {
    return {
      selectedRows: [],
      selectedTravelersRows: [],
    };
  },
  mounted() {
    this.selectedRows = this.tempContract[
      `flight_${this.product.id}_cancel_selected_rows`
    ]
      ? JSON.parse(
          this.tempContract[`flight_${this.product.id}_cancel_selected_rows`]
        )
      : [];

    this.selectedTravelersRows = this.tempContract[
      `flight_${this.product.id}_cancel_selected_travelers_rows`
    ]
      ? JSON.parse(
          this.tempContract[
            `flight_${this.product.id}_cancel_selected_travelers_rows`
          ]
        )
      : [];
  },
  methods: {
    onChangeProductRow(selectedRows) {
      this.tempContract[`flight_${this.product.id}_cancel_selected_rows`] =
        JSON.stringify(selectedRows);
      this.onChangeCancelProductData(this.tempContract);
    },
    onChangeTravelerRow(selectedRows) {
      this.tempContract[
        `flight_${this.product.id}_cancel_selected_travelers_rows`
      ] = JSON.stringify(selectedRows);
      this.onChangeCancelProductData(this.tempContract);
    },
    onChangeCancelProductData(tempContract) {
      this.$emit("onChangeCancelProductData", tempContract);
    },
  },
};
</script>

<style lang="sass" scoped>
.flight-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 12px
  font-weight: 500
  .cut
    text-decoration: line-through
    color: red
  .form
    background: #f6f6f6
    padding: 10px 20px 0
    margin: 20px -10px -11px -10px
    border-top: 1px solid #ddd
    border-bottom: 1px solid #ddd
  .label
    display: block
    font-size: 11px
    font-weight: 500
    color: #bbb
  .infos
    padding-bottom: 10px
    border-bottom: 1px dashed #ddd
    font-size: 12px
    color: #333
  .travellers
    .traveller
      padding: 0 0 8px 0
      margin: 0 0 8px
      border-bottom: 1px solid #eee
    .traveller:last-child
      border: 0
      padding-bottom:0
    .font
      font-weight: 600
      border-radius: 4px
      font-size: 12px
      background: #eee
      padding: 1px 5px 2px
      margin: 4px 4px 0px 0
      text-transform: uppercase
      word-break: break-all
      float: left
</style>
