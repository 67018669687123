<template>
  <aForm @submit.prevent="onSubmitForm" :form="form">
    <aRow :gutter="20">
      <aCol :span="6">
        <HayaTypeDateField
          label="Check-inn"
          :fieldKey="`${this.baseId}_checkin_typed_new`"
          :fieldToTransferValue="`${this.baseId}_checkin_new`"
          :form="form"
          :required="true"
          @onChangeDatePicker="onChangeDatePicker"
        />

        <aFormItem class="none">
          <aInput v-decorator="[`${this.baseId}_checkin_new`]" />
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <HayaTypeDateField
          label="Check-out"
          :fieldKey="`${this.baseId}_checkout_typed_new`"
          :fieldToTransferValue="`${this.baseId}_checkout_new`"
          :form="form"
          :required="true"
          @onChangeDatePicker="onChangeDatePicker"
        />

        <aFormItem class="none">
          <aInput v-decorator="[`${this.baseId}_checkout_new`]" />
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <HayaTextField
          label="Nº de dias"
          :fieldKey="`${baseId}_rates_new`"
          :form="form"
          :required="true"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaTextField
          label="N° de noites"
          :fieldKey="`${baseId}_nights_new`"
          :form="form"
          :required="true"
          :preIcon="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaTextField
          label="Localizador"
          :fieldKey="`${this.baseId}_locator_new`"
          :required="true"
          :form="form"
        />
      </aCol>

      <aCol :span="24">
        <aCheckbox
          v-model="activeFinancialData"
          @change="onChangeActiveFinancialData"
          class="f12"
        >
          Informar dados financeiros
        </aCheckbox>
        <aFormItem class="none">
          <aInput v-decorator="[`${baseId}_financial_data_active`]" />
        </aFormItem>
      </aCol>
    </aRow>

    <template v-if="activeFinancialData">
      <aRow class="mt-20" :gutter="20">
        <aCol :span="6">
          <HayaPriceField
            label="Valor total"
            :fieldKey="`${baseId}_total_price_new`"
            :form="form"
            :required="true"
            :preIcon="true"
            @onBlurPriceField="onBlurCalcProfit"
          />
        </aCol>

        <aCol :span="6">
          <HayaPriceField
            label="Valor neto"
            :fieldKey="`${baseFinancesId}_net_value_new`"
            :form="form"
            :required="true"
            :preIcon="true"
            @onBlurPriceField="onBlurCalcProfit"
          />
        </aCol>

        <aCol :span="6">
          <HayaPriceField
            label="Lucratividade"
            :fieldKey="`${baseFinancesId}_profit_value_new`"
            :form="form"
            :required="true"
            :preIcon="true"
            :fieldClass="'readonly'"
          />
        </aCol>

        <aCol :span="6">
          <HayaSelectField
            label="Fornecedor"
            :fieldKey="`${baseFinancesId}_supplier_id_new`"
            :list="
              productSupplier.list.map((item) => {
                return {
                  value: item.id,
                  label: `${item.id} - ${item.trading_name.toUpperCase()}`,
                };
              })
            "
            optionFilterProp="txt"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
          />
        </aCol>

        <aCol :span="6">
          <HayaPriceField
            label="Desconto"
            :fieldKey="`${baseFinancesId}_discount_value_new`"
            :form="form"
            :required="true"
            :preIcon="true"
          />
        </aCol>

        <aCol :span="6">
          <HayaTypeDateField
            label="Dia do pagamento"
            :fieldKey="`${baseFinancesId}_payday_new_typed`"
            :fieldToTransferValue="`${baseFinancesId}_payday_new`"
            :form="form"
            :required="true"
          />

          <aFormItem class="none">
            <aInput v-decorator="[`${baseFinancesId}_payday_new`]" />
          </aFormItem>
        </aCol>

        <aCol :span="24">
          <ContractProductsExtraTaxesAndIncentives
            :tempContract="tempContract"
            :product="product"
            :baseFinancesId="baseFinancesId"
            :baseId="baseId"
            :form="form"
          />
        </aCol>
      </aRow>
    </template>

    <div class="a-right mt-20">
      <aButton
        class="page-button f12 mb-30"
        type="primary"
        html-type="submit"
        :loading="loadingSubmit"
        :disabled="loadingSubmit"
      >
        Salvar novos dados
      </aButton>
    </div>
  </aForm>
</template>

<script>
import { parse as parseCurrency } from "vue-currency-input";
import { differenceInDays, parse as parseDate } from "date-fns";
import formatThings from "@/mixins/general/formatThings";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaTypeDateField from "@/components/general/fields/HayaTypeDateField.vue";
import HayaPriceField from "@/components/general/fields/HayaPriceField.vue";
import ContractProductsExtraTaxesAndIncentives from "./ContractProductsExtraTaxesAndIncentives.vue";

export default {
  name: "ContractCancelHotelForm",
  props: {
    product: Object,
    tempContract: Object,
  },
  components: {
    HayaTypeDateField,
    HayaTextField,
    HayaSelectField,
    HayaPriceField,
    ContractProductsExtraTaxesAndIncentives,
  },
  mixins: [formatThings, productSupplierMixins],
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
      baseId: `hotel_${this.product.id}`,
      baseFinancesId: `contract_finances_hotel_${this.product.id}`,
      loadingSubmit: false,
      activeFinancialData: false,
    };
  },
  mounted() {
    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    this.activeFinancialData =
      this.tempContract[`${this.baseId}_financial_data_active`] == 1
        ? true
        : false;

    setTimeout(() => {
      this.setProductFieldsValue();
    }, 100);
  },
  methods: {
    onChangeDatePicker(e) {
      console.log("onChangeDatePicker", e);

      setTimeout(() => {
        this.onChangeCalcDays();
      }, 300);
    },
    onChangeCalcDays() {
      const startValue = parseDate(
        this.form.getFieldValue(`${this.baseId}_checkin_new`),
        "yyyy-MM-dd",
        new Date()
      );

      const endValue = parseDate(
        this.form.getFieldValue(`${this.baseId}_checkout_new`),
        "yyyy-MM-dd",
        new Date()
      );

      const daysInHotel = differenceInDays(endValue, startValue);

      console.log("onChangeDatePicker", daysInHotel, endValue, startValue);

      if (daysInHotel) {
        this.form.setFieldsValue({
          [`${this.baseId}_rates_new`]: daysInHotel,
          [`${this.baseId}_nights_new`]: daysInHotel - 1,
        });
      }
    },
    onBlurCalcProfit() {
      const totalPrice = parseCurrency(
        this.tempContract[`${this.baseId}_total_price_new`]
      );
      const netValue = parseCurrency(
        this.tempContract[`${this.baseFinancesId}_net_value_new`]
      );

      console.log("onBlurCalcProfit", totalPrice, netValue);

      if (totalPrice && netValue)
        this.form.setFieldsValue({
          [`${this.baseFinancesId}_profit_value_new`]: totalPrice - netValue,
        });
    },
    setProductFieldsValue() {
      this.form.setFieldsValue({
        [`${this.baseId}_checkin_typed_new`]:
          this.tempContract[`${this.baseId}_checkin_typed_new`],
        [`${this.baseId}_checkin_new`]:
          this.tempContract[`${this.baseId}_checkin_new`],
        [`${this.baseId}_checkout_typed_new`]:
          this.tempContract[`${this.baseId}_checkout_typed_new`],
        [`${this.baseId}_checkout_new`]:
          this.tempContract[`${this.baseId}_checkout_new`],
        [`${this.baseId}_locator_new`]:
          this.tempContract[`${this.baseId}_locator_new`],
        [`${this.baseId}_rates_new`]:
          this.tempContract[`${this.baseId}_rates_new`],
        [`${this.baseId}_nights_new`]:
          this.tempContract[`${this.baseId}_nights_new`],
        [`${this.baseId}_total_price_new`]:
          this.tempContract[`${this.baseId}_total_price_new`],
        [`${this.baseFinancesId}_net_value_new`]:
          this.tempContract[`${this.baseFinancesId}_net_value_new`],
        [`${this.baseFinancesId}_supplier_id_new`]:
          this.tempContract[`${this.baseFinancesId}_supplier_id_new`],
        [`${this.baseFinancesId}_discount_value_new`]:
          this.tempContract[`${this.baseFinancesId}_discount_value_new`],
        [`${this.baseFinancesId}_payday_new_typed`]:
          this.tempContract[`${this.baseFinancesId}_payday_new_typed`],
        [`${this.baseFinancesId}_payday_new`]:
          this.tempContract[`${this.baseFinancesId}_payday_new`],
        [`${this.baseFinancesId}_profit_value_new`]:
          this.tempContract[`${this.baseFinancesId}_profit_value_new`],
      });
    },
    updateTempContract(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempContract[field] = value;

      if (Array.isArray(value)) {
        this.tempContract[field] = JSON.stringify(value);
      }

      this.tempContract = { ...this.tempContract };

      this.$emit("onChangeCancelProductData", this.tempContract);
    },
    onChangeActiveFinancialData(value) {
      this.form.setFieldsValue({
        [`${this.baseId}_financial_data_active`]: value.target.checked,
      });

      setTimeout(() => {
        this.setProductFieldsValue();
      }, 100);
    },

    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loadingSubmit = true;

          const hotelData = {};
          Object.keys(this.tempContract).forEach((key) => {
            if (
              ((key.startsWith(this.baseFinancesId) ||
                key.startsWith(this.baseId)) &&
                key.endsWith("_new")) ||
              key.endsWith("_financial_data_active")
            ) {
              hotelData[key] = this.tempContract[key];
            }
          });

          this.$http
            .post("/contract/update-multi-meta", {
              id: this.tempContract.id,
              ...values,
              ...hotelData,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            })
            .then(({ data }) => {
              this.$message.success(data.message);
            })
            .finally(() => (this.loadingSubmit = false));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-row {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-weight: 600;
  .type {
    font-size: 12px;
    color: #aaa;
  }
}
</style>
