<template>
  <div>
    <aAlert
      v-if="tempContract.based_contract_id"
      banner
      type="info"
      class="f12 mb-10"
      :message="`Este contrato foi gerado baseado no contrato ID ${tempContract.based_contract_id}.`"
    />

    <aRow v-if="tempContract.the_customer['person_type'] != undefined">
      <aCol class="content-row" :span="24">
        <h3>
          Contratante -
          {{
            tempContract.the_customer?.person_type
              ? tempContract.the_customer?.person_type
              : ""
          }}

          <a-rate
            v-if="
              tempContract.rate_customer && tempContract.status == `concluded`
            "
            style="zoom: 0.7; margin-left: 40px"
            disabled
            v-model="tempContract.rate_customer"
          />
        </h3>
        <aRow
          v-if="tempContract.the_customer?.person_type == 'Pessoa Física'"
          class=""
          :gutter="20"
        >
          <aCol :span="13">
            <span class="label">Nome Completo:</span>
            <a
              @click="
                $router.push(`/customers/edit/${tempContract.the_customer.id}`)
              "
              class="upper"
              >{{ tempContract.the_customer.id }} -
              {{ tempContract.the_customer.first_name }}
              {{ tempContract.the_customer.last_name }}</a
            >
          </aCol>

          <aCol :span="7">
            <span class="label">E-mail:</span>
            {{ tempContract.the_customer.email }}
          </aCol>

          <aCol :span="4">
            <span class="label">Nascimento:</span>
            {{ formatMultiDates(tempContract.the_customer.birthday) }}
          </aCol>
        </aRow>

        <aRow
          v-if="tempContract.the_customer?.person_type == 'Pessoa Física'"
          class="mt-10"
          :gutter="20"
        >
          <aCol :span="4">
            <span class="label">CPF:</span>
            {{ tempContract.the_customer.cpf }}
          </aCol>

          <aCol :span="4">
            <span class="label">RG:</span>
            {{ tempContract.the_customer.rg }}
          </aCol>

          <aCol :span="3">
            <span class="label">Emissor:</span>
            {{ tempContract.the_customer.rg_emissor }}
          </aCol>

          <aCol :span="4">
            <span class="label">Estado emissão:</span>
            {{ tempContract.the_customer.rg_state }}
          </aCol>

          <aCol :span="4">
            <span class="label">Celular:</span>
            {{ tempContract.the_customer.mobile_phone }}
          </aCol>

          <aCol :span="4">
            <span class="label">Telefone:</span>
            {{ tempContract.the_customer.phone }}
          </aCol>
        </aRow>

        <aRow
          v-if="tempContract.the_customer?.person_type == 'Pessoa Jurídica'"
          class=""
          :gutter="20"
        >
          <aCol :span="4">
            <span class="label">CNPJ:</span>
            {{ tempContract.the_customer.cnpj }}
          </aCol>

          <aCol :span="12">
            <span class="label">Razão social:</span>
            {{ tempContract.the_customer.company_name }}
          </aCol>

          <aCol :span="8">
            <span class="label">Nome Fantasia:</span>
            {{ tempContract.the_customer.trading_name }}
          </aCol>
        </aRow>

        <aRow class="mt-10" :gutter="20">
          <aCol :span="4">
            <span class="label">CEP:</span>
            {{ tempContract.the_customer.cep }}
          </aCol>

          <aCol :span="16">
            <span class="label">Logradouro:</span>
            {{ tempContract.the_customer.location_street }}
          </aCol>

          <aCol :span="4">
            <span class="label">Número:</span>
            {{ tempContract.the_customer.location_number }}
          </aCol>

          <aCol
            v-if="tempContract.the_customer.location_complement"
            class="mt-10"
            :span="8"
          >
            <span class="label">Complemento:</span>
            {{ tempContract.the_customer.location_complement }}
          </aCol>

          <aCol class="mt-10" :span="6">
            <span class="label">Bairro:</span>
            {{ tempContract.the_customer.location_neighborhood }}
          </aCol>

          <aCol class="mt-10" :span="6">
            <span class="label">Cidade:</span>
            {{ tempContract.the_customer.location_city }}
          </aCol>

          <aCol class="mt-10" :span="3">
            <span class="label">Estado:</span>
            {{ tempContract.the_customer.location_state }}
          </aCol>

          <aCol class="mt-10" :span="4">
            <span class="label">País:</span>
            {{ tempContract.the_customer.location_country }}
          </aCol>
        </aRow>
      </aCol>

      <aCol class="content-row" :span="24">
        <h3>Informações extra</h3>
        <aRow class="" :gutter="20">
          <aCol :span="10">
            <span class="label">origem do cliente:</span>
            {{
              tempContract.customer_origin
                ? tempContract.customer_origin
                : "Não informado"
            }}

            <span v-if="tempContract.customer_who_referred_id">
              (<a
                @click="
                  $router.push(
                    `/customers/edit/${tempContract.customer_who_referred_id}`
                  )
                "
                >{{ tempContract.customer_who_referred_name }}</a
              >)
            </span>
          </aCol>

          <aCol :span="4">
            <span class="label">ID Oportunidade:</span>
            <a
              v-if="!['0', 0, null].includes(tempContract.opportunity_id)"
              @click="
                $router.push(`/opportunity/edit/${tempContract.opportunity_id}`)
              "
            >
              {{ tempContract.opportunity_id }}
            </a>
            <div v-if="['0', 0, null].includes(tempContract.opportunity_id)">
              Não tem
            </div>
          </aCol>

          <aCol :span="4">
            <span class="label">ID Venda:</span>
            <a
              v-if="!['0', 0, null].includes(tempContract.sale_id)"
              @click="$router.push(`/sales/list?id=${tempContract.sale_id}`)"
            >
              {{ tempContract.sale_id }}
            </a>
            <div v-if="['0', 0, null].includes(tempContract.sale_id)">
              Não tem
            </div>
          </aCol>
        </aRow>
      </aCol>
    </aRow>

    <aRow v-if="travellersRows.length > 0">
      <aCol class="content-row" :span="24">
        <h3>VIAJANTES</h3>
        <aRow
          v-for="(traveller, index) in travellersRows"
          class="infos mb-10"
          :key="index"
          :gutter="20"
        >
          <aCol :span="5">
            <span class="label">Nome:</span>
            <span class="upper"
              >{{ tempContract[`traveller_${traveller.id}_first_name`] }}
            </span>
          </aCol>

          <aCol :span="6">
            <span class="label">Sobrenome:</span>
            <span class="upper">
              {{ tempContract[`traveller_${traveller.id}_last_name`] }}
            </span>
          </aCol>

          <aCol :span="3">
            <span class="label">Sexo:</span>
            <span class="upper">
              {{ tempContract[`traveller_${traveller.id}_gender`] }}
            </span>
          </aCol>

          <aCol :span="3">
            <span class="label">Nascimento:</span>
            {{
              formatMultiDates(
                tempContract[`traveller_${traveller.id}_birthday`]
              )
            }}
          </aCol>

          <aCol v-if="tempContract[`traveller_${traveller.id}_cpf`]" :span="3">
            <span class="label">CPF:</span>
            {{ tempContract[`traveller_${traveller.id}_cpf`] }}
          </aCol>

          <aCol v-if="tempContract[`traveller_${traveller.id}_rg`]" :span="3">
            <span class="label">RG:</span>
            {{ tempContract[`traveller_${traveller.id}_rg`] }}
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractCustomerDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      travellersRows: [],
    };
  },
  mounted() {
    this.travellersRows = this.tempContract.travellers_rows
      ? JSON.parse(this.tempContract.travellers_rows)
      : [];

    this.tempContract.rate_customer = parseInt(this.tempContract.rate_customer);
  },
};
</script>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 12px
  color: #333
  font-weight: 500
  .label
    display: block
    font-size: 11px
    font-weight: 500
    color: #aaa
  h4
    font-size: 10px !important
    font-weight: 600
    color: #be4178
  .travellers
    .traveller
      padding: 0 0 8px 0
      margin: 0 0 8px
      border-bottom: 1px solid #eee
    .traveller:last-child
      border: 0
      padding-bottom:0
    .font
      font-weight: 600
      border-radius: 4px
      font-size: 12px
      background: #eee
      padding: 1px 5px 2px
      margin: 4px 4px 0px 0
      text-transform: uppercase
      word-break: break-all
      float: left
  .p-row.sub
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 22px
  .p-row.no-dot
    border: 0
    .name
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name.hotel
      color: #00c100
      font-weight: 600
    .name.service
      color: #ff8000
      font-weight: 600
    .name.flight
      font-weight: 600
      color: #1890ff
  .p-row
    font-size: 12px
    border-bottom: 2px dotted #ccc
    margin: 4px
    .val
      top: 8px
      background: #FFF
      padding: 0 0px 0 5px
    .ico
      width: 25px
      text-align: center
      display: inline-block
    .name
      top: 8px
      background: #FFF
      padding: 0 10px 0 0px
      max-width: 220px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

      .num
        font-weight: 700
  padding: 10px
  .t1
    color: #00cd6b
  .t2
    color: #ff4545
  .t3
    color: #ff9a3e
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 5px
    color:#be4178
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
