<template>
  <aForm @submit.prevent="onSubmitForm" :form="form">
    <aRow :gutter="20">
      <aCol :span="6">
        <HayaTypeDateField
          label="Nova data de Embarque"
          :fieldKey="`${this.baseId}_departure_date_new_typed`"
          :fieldToTransferValue="`${this.baseId}_departure_date_new`"
          :form="form"
          :required="true"
          @onBlurHayaTypeDateField="calcDuration(rowId)"
          @onChangeDatePicker="calcDuration(rowId)"
        />

        <aFormItem class="none">
          <aInput v-decorator="[`${this.baseId}_departure_date_new`]" />
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label :class="'filled'" for>Novo Horário embarque</label>
          <aInput
            class="travel-input"
            placeholder="Horário"
            @blur="calcDuration(rowId)"
            v-mask="'##:##'"
            v-decorator="[
              `${this.baseId}_departure_time_new`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Classe"
          :fieldKey="`${this.baseId}_class_new`"
          :list="flightClasses"
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaSelectField
          label="Cia"
          :fieldKey="`${this.baseId}_airline_new`"
          :list="airlinesList"
          :required="true"
          :form="form"
          :allowClear="true"
          :showSearch="true"
        />
      </aCol>

      <aCol :span="6">
        <HayaTypeDateField
          label="Nova data de Desembarque"
          :fieldKey="`${this.baseId}_arrival_date_new_typed`"
          :fieldToTransferValue="`${this.baseId}_arrival_date_new`"
          :form="form"
          :required="true"
          @onBlurHayaTypeDateField="calcDuration(rowId)"
          @onChangeDatePicker="calcDuration(rowId)"
        />

        <aFormItem class="none">
          <aInput v-decorator="[`${this.baseId}_arrival_date_new`]" />
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label :class="'filled'" for>Novo Horário Desembarque</label>
          <aInput
            class="travel-input"
            placeholder="Horário"
            @blur="calcDuration(rowId)"
            v-mask="'##:##'"
            v-decorator="[
              `${this.baseId}_arrival_time_new`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <HayaTextField
          label="Duração do voo"
          :fieldKey="`${this.baseId}_duration_new`"
          :required="true"
          :form="form"
        />
      </aCol>

      <aCol :span="6">
        <HayaTextField
          label="Nº do vôo"
          :fieldKey="`${this.baseId}_number_new`"
          :required="true"
          :form="form"
        />
      </aCol>
    </aRow>

    <div class="a-right">
      <aButton
        class="page-button f12 mb-30"
        type="primary"
        html-type="submit"
        :loading="loadingSubmit"
        :disabled="loadingSubmit"
      >
        Salvar novos dados
      </aButton>
    </div>
  </aForm>
</template>

<script>
import { intervalToDuration } from "date-fns";
import formatThings from "@/mixins/general/formatThings";

import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaTypeDateField from "@/components/general/fields/HayaTypeDateField.vue";

export default {
  name: "ContractCancelFlightForm",
  props: {
    product: Object,
    tempContract: Object,
    rowId: Number || String,
  },
  components: { HayaTypeDateField, HayaSelectField, HayaTextField },
  mixins: [formatThings],
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
      baseId: `flight_${this.product.id}_section_${this.rowId}`,
      loadingSubmit: false,
      airlinesList: [],
      flightClasses: [
        { label: "ECONÔMICA", value: "ECONÔMICA" },
        { label: "ECONÔMICA PREMIUM", value: "ECONÔMICA PREMIUM" },
        { label: "EXECUTIVA", value: "EXECUTIVA" },
        { label: "FIRST CLASS", value: "FIRST CLASS" },
      ],
    };
  },
  mounted() {
    this.getAirlines();
    this.setProductFieldsValue();
  },
  methods: {
    setProductFieldsValue() {
      this.form.setFieldsValue({
        [`${this.baseId}_departure_date_new_typed`]:
          this.tempContract[`${this.baseId}_departure_date_new_typed`],
        [`${this.baseId}_duration_new`]:
          this.tempContract[`${this.baseId}_duration_new`],
        [`${this.baseId}_departure_time_new`]:
          this.tempContract[`${this.baseId}_departure_time_new`],
        [`${this.baseId}_departure_time_new`]:
          this.tempContract[`${this.baseId}_departure_time_new`],
        [`${this.baseId}_class_new`]:
          this.tempContract[`${this.baseId}_class_new`],
        [`${this.baseId}_airline_new`]:
          this.tempContract[`${this.baseId}_airline_new`],
        [`${this.baseId}_arrival_date_new_typed`]:
          this.tempContract[`${this.baseId}_arrival_date_new_typed`],
        [`${this.baseId}_arrival_date_new`]:
          this.tempContract[`${this.baseId}_arrival_date_new`],
        [`${this.baseId}_duration_new`]:
          this.tempContract[`${this.baseId}_duration_new`],
        [`${this.baseId}_arrival_time_new`]:
          this.tempContract[`${this.baseId}_arrival_time_new`],
        [`${this.baseId}_number_new`]:
          this.tempContract[`${this.baseId}_number_new`],
      });
    },
    updateTempContract(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempContract[field] = value;

      if (Array.isArray(value)) {
        this.tempContract[field] = JSON.stringify(value);
      }

      this.tempContract = { ...this.tempContract };

      this.$emit("onChangeCancelProductData", this.tempContract);
    },
    getAirlines() {
      this.$http
        .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
        .then(({ data }) => {
          this.airlinesList = data.data.map(({ name, logo }) => {
            return {
              label: logo
                ? `<img class="mr-5" src="${logo}" alt="logo" width="13" /> ${name}`
                : `${name}`,
              value: name,
            };
          });
        });
    },
    calcDuration() {
      setTimeout(() => {
        let departureDate = this.form.getFieldValue(
          `${this.baseId}_departure_date_new`
        );

        let departureTime = this.form.getFieldValue(
          `${this.baseId}_departure_time_new`
        );

        let arrivalDate = this.form.getFieldValue(
          `${this.baseId}_arrival_date_new`
        );

        let arrivalTime = this.form.getFieldValue(
          `${this.baseId}_arrival_time_new`
        );

        if (departureDate && departureTime && arrivalDate && arrivalTime) {
          let interval = intervalToDuration({
            start: new Date(departureDate + " " + departureTime),
            end: new Date(arrivalDate + " " + arrivalTime),
          });

          this.form.setFieldsValue({
            [`${this.baseId}_duration_new`]: `${this.formatDateTimeZeros(
              interval.hours
            )}:${this.formatDateTimeZeros(interval.minutes)}`,
          });
        }
      }, 200);
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loadingSubmit = true;
          this.$http
            .post("/contract/update-multi-meta", {
              id: this.tempContract.id,
              [`flight_${this.product.id}_cancel_selected_rows`]:
                this.tempContract[
                  `flight_${this.product.id}_cancel_selected_rows`
                ],
              ...values,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            })
            .then(({ data }) => {
              this.$message.success(data.message);
            })
            .finally(() => (this.loadingSubmit = false));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-row {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-weight: 600;
  .type {
    font-size: 12px;
    color: #aaa;
  }
}
</style>
