<template>
  <aForm @submit.prevent="onSubmitForm" :form="form">
    <aRow class="data-row mb-20">
      <aCol :span="6">
        <span class="title cprimary">Dados financeiros</span>
        <span class="label">Valor:</span>
        <span v-html="productValues().total.value" />
      </aCol>

      <aCol :span="6">
        <span class="label">A pagar:</span>
        <span v-html="productValues().toPay.value" />
      </aCol>

      <aCol :span="6">
        <span class="label">Taxas:</span>
        <span v-html="productValues().taxes.value ?? 'R$ 0'" />
      </aCol>

      <aCol :span="6">
        <span class="label">Lucratividade:</span>
        <span v-html="productValues().profit.value" />
      </aCol>
    </aRow>

    <aRow v-if="activeFinancialData" class="data-row mb-30">
      <aCol :span="6">
        <span class="title cgreen">Novos dados financeiros</span>
        <span class="label">Valor:</span>
        <span v-html="newProductValues().total" />
      </aCol>

      <aCol :span="6">
        <span class="label">A pagar:</span>
        <span v-html="newProductValues().toPay" />
      </aCol>

      <aCol :span="6">
        <span class="label">Taxas:</span>
        <span v-html="newProductValues().taxes ?? 'R$ 0'" />
      </aCol>

      <aCol :span="6">
        <span class="label">Lucratividade:</span>
        <span v-html="newProductValues().profit" />
      </aCol>
    </aRow>

    <aRow class="fees" :gutter="20" align="middle">
      <aCol :span="11">
        <aCheckbox v-model="changeTax" @change="onChangeChangeTax">
          Taxa de alteração
        </aCheckbox>
        <aFormItem class="none">
          <aInput v-decorator="[`${baseFinancesId}_change_tax_active`]" />
        </aFormItem>
      </aCol>

      <aCol v-if="changeTax" :span="8">
        <aFormItem class="travel-input-outer">
          <label class="filled" for> Valor </label>
          <aInput
            class="travel-input"
            placeholder="Insira"
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
            }"
            v-decorator="[
              `${baseFinancesId}_change_tax_value`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>
    </aRow>

    <aRow class="fees" :gutter="20" align="middle">
      <aCol :span="11">
        <aCheckbox v-model="fineFee" @change="onChangeFineFee">
          Multa de cancelamento do cliente
        </aCheckbox>
        <aFormItem class="none">
          <aInput v-decorator="[`${baseFinancesId}_fine_fee_active`]" />
        </aFormItem>
      </aCol>

      <aCol v-if="fineFee" :span="5">
        <HayaNumberField
          label="Porcentagem"
          :fieldKey="`${baseFinancesId}_fine_fee_percentage`"
          :step="1"
          :preIcon="true"
          :required="false"
          :min="0"
          :max="100"
          @onChangeNumberField="onChangeFineFeePercentage"
        />
      </aCol>

      <aCol v-if="fineFee" :span="8">
        <aFormItem class="travel-input-outer">
          <label class="filled" for> Valor </label>
          <aInput
            class="travel-input"
            placeholder="Insira"
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
            }"
            v-decorator="[
              `${baseFinancesId}_fine_fee_value`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>
    </aRow>

    <aRow class="fees" :gutter="20" align="middle">
      <aCol :span="16">
        <aRow :gutter="20" type="flex" justify="start" align="middle">
          <aCol>
            <aCheckbox
              v-model="supplierFineFee"
              @change="onChangeSupplierFineFee"
            >
              Multa do fornecedor
            </aCheckbox>
            <aFormItem class="none">
              <aInput
                v-decorator="[`${baseFinancesId}_supplier_fine_fee_active`]"
              />
            </aFormItem>
          </aCol>
          <aCol class="truncate supplier">
            {{ tempContract[`${baseFinancesId}_supplier`] }}</aCol
          >
        </aRow>
      </aCol>

      <aCol v-if="supplierFineFee" :span="8">
        <aFormItem class="travel-input-outer">
          <label class="filled" for> Valor Multa do Fornecedor </label>
          <aInput
            class="travel-input"
            placeholder="Insira"
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
            }"
            v-decorator="[
              `${baseFinancesId}_supplier_fine_fee_value`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>
    </aRow>

    <div class="a-right mt-10">
      <aButton
        class="page-button f12 mb-30"
        type="primary"
        html-type="submit"
        :loading="loadingSubmit"
        :disabled="loadingSubmit"
      >
        Salvar novos dados
      </aButton>
    </div>
  </aForm>
</template>

<script>
import { parse } from "vue-currency-input";
import HayaNumberField from "@/components/general/fields/HayaNumberField.vue";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ContractCancelHotelFinancialForm",
  props: {
    type: String,
    product: Object,
    tempContract: Object,
    allContractData: Object,
  },
  components: { HayaNumberField },
  mixins: [formatThings],
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
      baseId: `hotel_${this.product.id}`,
      baseFinancesId: `contract_finances_hotel_${this.product.id}`,
      loadingSubmit: false,
      changeTax: false,
      fineFee: false,
      supplierFineFee: false,
      activeFinancialData: false,
    };
  },
  mounted() {
    this.changeTax =
      this.tempContract[`${this.baseFinancesId}_change_tax_active`] == 1
        ? true
        : false;
    this.fineFee =
      this.tempContract[`${this.baseFinancesId}_fine_fee_active`] == 1
        ? true
        : false;
    this.supplierFineFee =
      this.tempContract[`${this.baseFinancesId}_supplier_fine_fee_active`] == 1
        ? true
        : false;

    this.activeFinancialData =
      this.tempContract[`${this.baseId}_financial_data_active`] == 1
        ? true
        : false;

    this.setFieldsValues();
  },
  methods: {
    setFieldsValues() {
      setTimeout(() => {
        this.form.setFieldsValue({
          [`${this.baseFinancesId}_change_tax_value`]:
            this.tempContract[`${this.baseFinancesId}_change_tax_value`],
          [`${this.baseFinancesId}_fine_fee_percentage`]:
            this.tempContract[`${this.baseFinancesId}_fine_fee_percentage`],
          [`${this.baseFinancesId}_fine_fee_value`]:
            this.tempContract[`${this.baseFinancesId}_fine_fee_value`],
          [`${this.baseFinancesId}_supplier_fine_fee_value`]:
            this.tempContract[`${this.baseFinancesId}_supplier_fine_fee_value`],
        });
      }, 100);
    },
    updateTempContract(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempContract[field] = value;

      if (Array.isArray(value)) {
        this.tempContract[field] = JSON.stringify(value);
      }

      this.tempContract = { ...this.tempContract };

      this.$emit("onChangeCancelProductData", this.tempContract);
    },
    productValues() {
      const total = this.allContractData.hotel.toReceiveList.filter((item) => {
        return item.id == `hotel-${this.product.id}`;
      });

      const toPay = this.allContractData.hotel.netList.filter((item) => {
        return item.id == `hotel-${this.product.id}`;
      });

      const profit = this.allContractData.hotel.profitList.filter((item) => {
        return item.id == `hotel-${this.product.id}`;
      });

      const taxes = this.allContractData.hotel.extraFeesList.filter((item) => {
        return item.id == `hotel-${this.product.id}`;
      });

      return {
        total: total.length ? total[0] : {},
        toPay: toPay.length ? toPay[0] : {},
        profit: profit.length ? profit[0] : {},
        taxes: taxes.length ? taxes[0] : {},
      };
    },
    newProductValues() {
      let total = parse(this.tempContract[`${this.baseId}_total_price_new`]);
      let toPay = parse(
        this.tempContract[`${this.baseFinancesId}_net_value_new`]
      );
      let profit = 0;
      let taxes = 0;

      if (
        this.tempContract[`${this.baseFinancesId}_taxes_rows_new`] != undefined
      ) {
        JSON.parse(
          this.tempContract[`${this.baseFinancesId}_taxes_rows_new`]
        ).forEach((tax) => {
          if (
            this.tempContract[
              `${this.baseFinancesId}_tax_${tax.id}_type_new`
            ] &&
            this.tempContract[`${this.baseFinancesId}_tax_${tax.id}_value_new`]
          ) {
            taxes += parse(
              this.tempContract[
                `${this.baseFinancesId}_tax_${tax.id}_value_new`
              ]
            );

            if (
              this.tempContract[
                `${this.baseFinancesId}_tax_${tax.id}_add_in_total_new`
              ] == true ||
              this.tempContract[
                `${this.baseFinancesId}_tax_${tax.id}_add_in_total_new`
              ] == 1
            ) {
              total += parse(
                this.tempContract[
                  `${this.baseFinancesId}_tax_${tax.id}_value_new`
                ]
              );
            }
          }
        });
      }

      if (
        this.tempContract[`${this.baseFinancesId}_incentives_rows_new`] !=
        undefined
      ) {
        JSON.parse(
          this.tempContract[`${this.baseFinancesId}_incentives_rows_new`]
        ).forEach((incentive) => {
          if (
            this.tempContract[
              `${this.baseFinancesId}_incentive_${incentive.id}_type_new`
            ] == "%"
          ) {
            profit += parseFloat(
              this.tempContract[
                `${this.baseFinancesId}_incentive_${incentive.id}_percentage_value_new`
              ]
            );

            if (
              this.tempContract[
                `${this.baseFinancesId}_incentive_${incentive.id}_add_in_total_new`
              ] == true ||
              this.tempContract[
                `${this.baseFinancesId}_incentive_${incentive.id}_add_in_total_new`
              ] == 1
            ) {
              total += parseFloat(
                this.tempContract[
                  `${this.baseFinancesId}_incentive_${incentive.id}_percentage_value_new`
                ]
              );
            }
          } else {
            profit += parse(
              this.tempContract[
                `${this.baseFinancesId}_incentive_${incentive.id}_value_new`
              ]
            );

            if (
              this.tempContract[
                `${this.baseFinancesId}_incentive_${incentive.id}_add_in_total_new`
              ] == true ||
              this.tempContract[
                `${this.baseFinancesId}_incentive_${incentive.id}_add_in_total_new`
              ] == 1
            ) {
              total += parse(
                this.tempContract[
                  `${this.baseFinancesId}_incentive_${incentive.id}_value_new`
                ]
              );
            }
          }
        });
      }

      return {
        total: this.formatPricePtBr(total),
        toPay: this.formatPricePtBr(toPay),
        profit: this.formatPricePtBr(total - toPay + profit),
        taxes: this.formatPricePtBr(taxes),
      };
    },
    onChangeFineFee(value) {
      this.form.setFieldsValue({
        [`${this.baseFinancesId}_fine_fee_active`]: value.target.checked,
      });

      this.setFieldsValues();
    },
    onChangeChangeTax(value) {
      this.form.setFieldsValue({
        [`${this.baseFinancesId}_change_tax_active`]: value.target.checked,
      });
      this.setFieldsValues();
    },
    onChangeSupplierFineFee(value) {
      this.form.setFieldsValue({
        [`${this.baseFinancesId}_supplier_fine_fee_active`]:
          value.target.checked,
      });
      this.setFieldsValues();
    },
    onChangeFineFeePercentage(value) {
      const total = parse(this.tempContract[`${this.baseId}_total_price`]);
      const newValue = value ? (total * value) / 100 : 0;
      this.form.setFieldsValue({
        [`${this.baseFinancesId}_fine_fee_value`]: newValue
          .toFixed(2)
          .replace(".", ","),
      });
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loadingSubmit = true;
          this.$http
            .post("/contract/update-multi-meta", {
              id: this.tempContract.id,
              [`${this.baseFinancesId.id}_fine_fee_active`]:
                this.tempContract[`${this.baseFinancesId.id}_fine_fee_active`],
              ...values,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            })
            .then(({ data }) => {
              this.$message.success(data.message);
            })
            .finally(() => (this.loadingSubmit = false));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fees {
  font-size: 13px;
  padding: 0 40px 0 20px;
  color: #646464;
  min-height: 66px;
  label.ant-checkbox-wrapper {
    font-size: 13px;
    margin-top: 8px;
  }
  .supplier {
    width: 200px;
    display: inline-block;
    font-weight: 600;
    line-height: 1.9;
    margin-top: 8px;
  }
}

.data-row {
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd !important;
  margin: 0 0 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  .title {
    position: absolute;
    top: -19px;
    font-size: 12px;
    background: #fff;
    font-weight: 600;
  }
  .label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #aaa;
  }
}

.title-row {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-weight: 600;
  .type {
    font-size: 12px;
    color: #aaa;
  }
}
</style>
