<template>
  <div>
    <ContractModalHeader
      :tempContract="tempContract"
      :contract="contract"
      :allContractData="allContractData"
      :cancellationMode="cancellationMode"
      :cancelSpecificProducts="cancelSpecificProducts"
      :disableCancelSale="disableCancelSale"
      @changeCancelSpecificProducts="changeCancelSpecificProducts"
    />

    <ContractModalTabs
      :tempContract="tempContract"
      :contract="contract"
      :allContractData="allContractData"
      :cancellationMode="cancellationMode"
      :contractedServices="contractedServices"
      :emailIsSent="emailIsSent"
      :cancelSpecificProducts="cancelSpecificProducts"
      :disableCancelSale="disableCancelSale"
      @onClickChangeCancellationMode="onClickChangeCancellationMode"
      @updateContractsList="updateContractsList"
    />
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "../mixins/contractMixins";
import ContractModalTabs from "../tabs/ContractModalTabs.vue";
import ContractModalHeader from "../sections/ContractModalHeader.vue";

export default {
  name: "ContractModal",
  components: {
    ContractModalTabs,
    ContractModalHeader,
  },
  props: { contractId: String },
  mixins: [contractMixins, formatThings],
  data() {
    return {
      contract: {},
      tempContract: {},
      contractedServices: [],
      contractDataFromDatabase: {},
      emailIsSent: false,
      cancellationMode: false,
      disableCancelSale: true,
      cancelSpecificProducts: false,
    };
  },
  beforeMount() {
    this.getContract();
  },
  mounted() {
    setTimeout(() => {
      if (this.tempContract.id != undefined) {
        if (this.tempContract.sale_id == 0) this.autoUpdateSaleIdOnContract();
      }
    }, 1000);
  },
  methods: {
    updateContractsList() {
      this.$emit("updateContractsList");
    },
    changeCancelSpecificProducts(val) {
      this.cancelSpecificProducts = val;
    },
    onClickChangeCancellationMode(status) {
      this.cancellationMode = status;
    },
    getContract() {
      this.$http
        .post(`/contract-v2/details?id=${this.contractId}`)
        .then(({ data }) => {
          this.contract = data;
          this.tempContract = {
            ...data,
            the_customer: data.customer,
          };

          let allFieldsSize = Object.values(data.meta).length,
            values = Object.values(data.meta),
            keys = Object.keys(data.meta);

          for (let i = 0; i < allFieldsSize; i++) {
            this.tempContract[keys[i]] = values[i];
          }

          this.tempContract.user = this.tempContract.user.id;
          this.tempContract.customer_id = this.tempContract.customer.id;

          delete this.tempContract.meta;
          delete this.tempContract.customer;

          this.contractDataFromDatabase = this.tempContract;

          this.contractedServices = data.contracted_services
            ? JSON.parse(data.contracted_services)
            : [];

          this.cancelSpecificProducts =
            this.tempContract.cancel_specific_products == 1 ? true : false;

          if (this.tempContract.contract_email_is_sent != undefined) {
            this.emailIsSent =
              this.tempContract.contract_email_is_sent == 1 ? true : false;
          }

          this.contractPricingData(this.tempContract);
        });
    },
    autoUpdateSaleIdOnContract() {
      this.$http
        .post(
          `/sale/list?page=1&per_page=1&contract-id=${this.tempContract.id}&order=desc&order-by=created`
        )
        .then(({ data }) => {
          this.tempContract.sale_id = data.data[0].id;

          this.$http.post("/contract-v2/update-field", {
            id: this.tempContract.id,
            field: "sale_id",
            value: data.data[0].id,
          });
        });
    },
  },
};
</script>
