<template>
  <div class="content-row">
    <aRow :gutter="20">
      <aCol :span="5">
        <span class="label">Checkin:</span>
        {{ product.checkin ? formatMultiDates(product.checkin) : "" }}
      </aCol>

      <aCol :span="5">
        <span class="label">Checkout:</span>
        {{ product.checkout ? formatMultiDates(product.checkout) : "" }}
      </aCol>

      <aCol :span="3">
        <span class="label"> Diarias:</span>
        {{ product.days }}
      </aCol>

      <aCol :span="10">
        <span class="label"> Regime Alimentar:</span>
        {{ product.diet }}
      </aCol>

      <aCol class="mt-10" :span="10">
        <span class="label"> Localizador:</span>
        {{ product.locator }}
      </aCol>

      <aCol class="mt-10 mb-5" :span="24"><h4 class="mt-10">QUARTOS</h4> </aCol>
    </aRow>

    <aRow
      v-for="(room, index) in product.rooms"
      :key="index"
      class="rooms mb-10"
      :gutter="20"
    >
      <aCol :span="10">
        <span class="label">Acomodação {{ index + 1 }}:</span>
        {{ room.name }}
      </aCol>

      <aCol class="travellers mt-10" :span="24">
        <h4 class="mt-10">HÓSPEDES</h4>
        <aRow
          class="traveller upper"
          v-for="(traveller, index) in room.travellers"
          :key="index"
        >
          <aCol :span="18">
            {{ traveller.name }}
          </aCol>

          <aCol :span="6">
            {{ formatMultiDates(traveller.birthday) }}
          </aCol>
        </aRow>
      </aCol>
    </aRow>

    <div class="form" v-if="type === 'edit'">
      <ContractCancelHotelForm
        class="mt-30"
        :product="product"
        :tempContract="tempContract"
        @onChangeCancelProductData="onChangeCancelProductData"
      />
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import ContractCancelHotelForm from "./ContractCancelHotelForm.vue";

export default {
  name: "ContractModalHotelRow",
  props: {
    product: Object,
    tempContract: Object,
    allContractData: Object,
    type: String,
  },
  components: { ContractCancelHotelForm },
  mixins: [formatThings],
  methods: {
    onChangeCancelProductData(tempContract) {
      this.$emit("onChangeCancelProductData", tempContract);
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-weight: 500
  .form
    background: #f6f6f6
    padding: 10px 20px 0
    margin: 20px -10px -11px -10px
    border-top: 1px solid #ddd
    border-bottom: 1px solid #ddd
  .infos
    font-size: 12px
    color: #333
  .label
    display: block
    font-size: 11px
    font-weight: 500
    color: #aaa
  h4
    font-size: 10px !important
    font-weight: 600
    color: #be4178
  .travellers
    .traveller
      padding: 0 0 8px 0
      margin: 0 0 8px
      border-bottom: 1px solid #eee
      font-size: 12px
    .traveller:last-child
      border: 0
      padding-bottom:0
</style>
